* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.wrapperr {
  /* height: 100vh;
  width: 100%; */
  position: relative;
  overflow-x: hidden;
  /* perspective: 10px; */
}

.top {
  display: flex;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  z-index: -1;
}

#img1 {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}

.main-text {
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 3px 5px 2px #474747;
}

.section {
  background-color: rgb(255, 255, 255);
  color: aliceblue;
  padding: 2rem 0;
}

.secheading {
  font-size: 5rem;
  padding: 0 10rem;
}

.bgn {
  background-image: url(../Assets/60xr1.jpg);
  object-fit: cover;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  /* Ensure full height for the image container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.texts {
  position: relative;
  margin: 5rem 0;
  padding: 0 10rem;
  font-weight: 400;
  line-height: 26px;
  font-size: 16px;
  color: #9e9e9e;
}

.bgs {
  background-image: url(../Assets/60xr2.jpg);
  object-fit: cover;
}

.bga {
  position: relative;
  background-attachment: fixed;
  min-height: 800px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.desc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.newlg {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin-top: -8rem;
  bottom: 0;
  transform: translateY(80%);
}

@media (max-width: 768px) {
  #img1 {
    height: auto;
    width: 100%;
  }

  .main-text {
    font-size: 2rem;
  }

  .secheading {
    font-size: 3rem;
    padding: 0 2rem;
  }

  .texts {
    position: relative;
    margin: 0rem;
    padding: 0 2rem;
    font-size: 14px;
    text-align: justify;
  }

  .newlglEAR {
    height: 100px;
    margin-top: -10rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateY(60%);
  }

  .bga,
  .bgs {
    margin-top: 10px;
    min-height: 450px;
    background-attachment: scroll;
    background-size: contain;
  }
}