* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  position: fixed;
  height: 15vh;
  background-color: transparent;
  padding: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  z-index: 1000;
  background-color: rgba(77, 74, 74, 0.45);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

nav.scrolled {
  background-color: white;
  /* color: white; */
}

nav.scrolled main a {
  color: black;
}

nav .logo {
  height: 80px;
  width: 75px;
  object-position: center;
  /* object-fit: cover; */
  margin-top: -5px;
  /* padding:; */
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.25); */
  border-radius: 10px;
  margin-left: 80px;
}

nav main {
  display: flex;
  gap: 0.8rem;
  justify-content: space-around;
  align-items: center;
  margin-right: 30px;
}

nav main a {
  color: white;
  font-size: large;
  font-weight: bold;
  /* font-weight: 1000; */
  font-family: sans-serif;
  text-decoration: none;
  padding: 0.5rem 1.2rem;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
  z-index: 1;
}

nav main a:hover {
  /* background-color: ; */
  /* color: white; */
  /* border-radius: 5px; */
  z-index: 2;
  /* text-decoration: underline; */

}

.under:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  color: white;
  position: absolute;
  background-color: white;
  min-width: 280px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.dropdown:hover .dropdown-content {
  display: block;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 2;
  margin-right: 40px;
}

.icon {
  color: white;
  font-size: 24px;
}

@media (max-width: 768px) {
  /* .hamburger {
    display: flex;
    margin-left: 10px;
  } */


  .hamburger {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
  }

  .hamburger .icon {
    font-size: 1.5rem;
    color: white;
    /* Default color */
  }

  .hamburger.scrolled .icon {
    color: black;
    /* Color when scrolled */

  }

  .hamburger.scrolled a {
    color: white;
  }

  nav main {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #333;
    text-align: center;
  }

  nav main.open {
    display: flex;
  }

  nav main a {
    padding: 1rem;
    width: 100%;
    color: white;
  }

  .dropdown-content {
    position: relative;
    min-width: 100%;
    box-shadow: none;
  }

  nav .logo {
    height: 55px;
    width: 50px;
    object-position: center;
    /* object-fit: cover; */
    margin-top: -5px;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 10px;
    margin-left: 0;
  }

  nav {
    height: 11vh !important;
  }




}

.fonts {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}