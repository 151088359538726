.charter-container {
    display: flex; 
    padding: 5rem;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;  
    height: 70vh;
    
   
  }
  
  .charter-text h2 {
    text-align: center;
    margin-top: 5px;
    color: black;
    margin-bottom: 20px;
  }
  
  .charter-text {
    flex: 1;
    font-size: 1.2rem;
    color: gray;
    width: 50%;
    align-items: center;
    margin-right: 150px;
    line-height: normal;
  }
  
  .charter-image {
    flex: 1;
    width: 100%;
    height: auto;
 
    border-radius: 20px;
    transition: transform 0.3s ease-in-out;
  }