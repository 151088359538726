
.foot{
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    
}



.footer {
    /* border-top: solid 2px gray; */
    background-color: white;
    /* color: #fff; */
    padding: 2rem;
    height: 100%;
    width: 100vw;
    text-align: center;
  }
  
  .fix-4 {
    color: black;
    font-size: 1.5rem;
    /* text-align: justify; */
    margin-top: 2rem;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    font-family: "Roboto", sans-serif;
  
  }
  
  .fix-5 {
    font-size: 1.7rem;
    text-decoration: underline;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    font-family: "Roboto", sans-serif;
    /* text-align: justify; */
  }
  
  .footer a {
    color: rgba(116, 72, 183, 0.637);
    /* text-decoration: underline; */
    
  }
  
  .query-form {
    margin-top: 2rem;
    height:auto;
  }
  
  .query-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .query-form input,
  .query-form textarea {
    width: 100%;
    height: 40px;
    max-width: 500px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .query-form button {
    padding: 0.5rem 2rem;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    height: 60px;
    width: 180px;
    text-align: center;
    font-size: 20px;
    border-radius: 12px;
  }
  
  .query-form button:hover {
    background-color: #0056b3;
  }


  
  
 .last{
  color: gray;
  text-align: center;
  font-size: 16px;
  margin-top: 7%;
  position: relative;
  top: -10%;
  background-color: lightgray;
 }

  
  @media (max-width: 768px) {
    .footer {
        padding: 1rem;
       
    }
    .foot{
      height: 65% !important;
      height: 100vw;
    }

    .fix-4, .fix-5 {
        font-size:16px !important;
        margin-top: 1rem;
    }

    .query-form input,
    .query-form textarea {
        width: 90%;
        height: 35px;
        max-width: none;
    }

    .query-form button {
        padding: 0.5rem 1rem;
        height: 50px;
        width: 150px;
        font-size: 16px;
    }

    .footer-additional {
        padding: 2rem;
        height: auto;
    }
    .last{
      margin-top: 0;
    }
}