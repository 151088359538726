@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;


}

.wrapperf {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;


}

.container-fractional {
  padding-left: 5%;
  padding-right: 5%;
}

.herof {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.herof-vid {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

/* .hero-text {
  position: absolute;
  top: 25%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  padding: 0 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} */

.hero-text {
  position: absolute;
  top: 35%;
  left: 20%;
  color: white;
  text-align: center;
  z-index: 1;
  padding: 0 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  opacity: 0;
  /* Initially hidden */
  animation: fadeInOut 6s forwards;
  /* Animation to handle appearance and disappearance */
}



.hero-text p {
  font-weight:
    700;
  font-size:
    52.8px;
  text-shadow: 3px 5px 2px #474747;
}

ul {
  color:
    #9e9e9e;
}

.textf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding-left: 5%;
  padding-right: 5%;

}

.textf2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding-left: 5%;
  padding-right: 5%;

}

.sec1 {
  position: relative;
  width: 100%;
  height: 70%;
  /* height: auto; */
  position: relative;
  padding: 1.5rem;
  /* z-index: 999; */
  /* overflow-y: scroll; */

  font-size: 1.2rem;

  font-weight: lighter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;


  /* color:black; */
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;

}

.sec1 p {
  text-align: justify;
  margin-left: -5%;
  color: #9e9e9e;
}

.sec1-img {
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.sec1-img img {
  width: 100%;
  object-fit: cover;
  /* right: 5%; */
  border-radius: 20px;
}

/* fix from here */






.contentf {

  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 4rem 12rem 4rem 12rem;
  text-align: justify;
  color: white;
  background: linear-gradient(0deg, rgba(170, 182, 194, 1) 0%, rgba(24, 52, 91, 1) 87%, rgba(15, 41, 78, 1) 100%);
  object-fit: contain;
}

.contentf h2 {
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;
  margin-top: 0.8rem;
}

.contentf p {
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;
}


@media (max-width: 768px) {
  .textf {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: auto;
    width: 100%;
  }

  .textf2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: auto;
    width: 100%;
  }

  .sec1 {
    width: 90%;
    height: auto;
    padding: 0rem;
    text-align: justify;
  }

  .sec1 p {
    margin-left: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .sec1-img {
    width: 80%;
  }

  .herof {
    position: relative;
    width: 100vw;
    height: 33vh;
  }

  .contentf {
    padding: 2.5rem;
    /* Reduced padding for mobile */
    height: auto;
  }

  .contentf h2 {
    font-size: 16px;
    /* Smaller font size for headings */
    line-height: 27px;
    /* Adjusted line height */
    text-align: start;
  }

  .contentf p {
    font-size: 14px;
    /* Smaller font size for paragraphs */
    line-height: 22px;
    /* Adjusted line height */
  }

  .sec-li {
    font-size: 14px;
  }
}