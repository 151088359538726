@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

/* Container className for main content */
.cmain {
  /* margin-bottom:200px; */
  margin-top: 200px;
  display: flex;
  /* Flexbox layout for centering */
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  text-align: center;
  /* Center align text */
  padding: 20px;
  /* Add padding around the container */
  margin: 0 auto;
  /* Center container in the viewport */
}

/* className for the top section */
.ctop {
  max-width: 800px;
  /* Limit the width of the content */
  width: 100%;
  /* Make sure it takes full width on smaller screens */
  margin: 0 auto;
  /* Center the top section */
}

/* Heading styles */
.ctop h1 {
  font-size: 30px;
  /* Set font size for headings */
  margin-bottom: 20px;
  /* Add space below headings */
}

/* Paragraph styles */
.ctop p {
  font-size: 16px;
  /* Set font size for paragraphs */
  line-height: 1.5;
  /* Increase line spacing for readability */
  margin-bottom: 20px;
  /* Add space below paragraphs */
  align-items: baseline;
  font-weight:
    400;
  line-height:
    26px;
  color:
    #808080;
}

/* Container for image */
.cimg {
  margin: 20px 0;
  /* Add space around the image */
}

/* Image styles */
.cimg img {
  max-width: 100%;
  /* Make image responsive */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 10px;
  /* Add rounded corners */
}

@media (max-width: 768px) {
  .cmain {
    padding: 2rem;
  }

  .ctop h1 {
    margin-top: 20%;
    font-size: 20px;
    /* margin-bottom:90px; */
  }
  .ctop p {
    text-align: center !important;
  }

  .respo-pc {
    font-size: 14px !important;
    /* text-align: center !important; */
  }

  .respo-h {
    font-size: 16px !important;
  }

}