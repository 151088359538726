@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* General Styles */
.wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  /* overflow-x: hidden; */
}

/* Home Container */
.home-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* overflow-x: hidden; */
}

.topv {
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* object-fit:cover; */
  /* background-position: center; */
  z-index: -1;
  pointer-events: none;
}

.video-fix {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  pointer-events: none;
  /* z-index: -1; */
}


.overlay {
  box-sizing: border-box;

  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  padding: 0 1rem;
  height: 100%;
  width: 70%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.overlay h1 {
  font-size: 2rem;
  /* margin-bottom: 1rem; */
}



.overlay p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

/* Content Styles */
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  padding: 2rem;
  color: gray;
  margin-top: 4rem;
  /* align-items: center; */
}

.content-para {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  /* padding: 2rem; */
}

.why {
  font-size: 2rem;
  color: black;
  text-align: center;
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;
  color:
    #263238;

}

.content p {


  /* width: 55%; */
  margin-bottom: 2.7rem;
  padding-left: 8rem;
  padding-right: 8rem;
  text-align: center;
  line-height: normal;
  font-weight: lighter;
  text-align: justify;
  font-weight:
    400;
  font-size:
    16px;
  color:
    #9e9e9e;
  line-height:
    26px;
}




.decor {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-weight: bold;
  /* margin-top: -55px; */
}






/* Image Container */
.image-container {
  min-height: 60vh;
  width: 100%;
  /* margin-bottom: 80px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1.5rem;
  padding: 2.5rem;
  background-color: #2b2b2b;
  background: rgb(170, 182, 194);
  background: linear-gradient(0deg, rgba(170, 182, 194, 1) 0%, rgba(24, 52, 91, 1) 87%, rgba(15, 41, 78, 1) 100%);
  padding: 4rem;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.image-container img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
  /* Optional: to maintain a consistent aspect ratio */
  aspect-ratio: auto;
}

.imageOne {
  width: 32%;
  height: 100%;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;

}

.imageOne:hover {
  transform: scale(0.95);
}

.image:hover {
  transform: scale(0.95);
}

/* 
.image-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 2rem 0;
  position: relative;
  border-radius: 15px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.image {
  min-width: 100%;
  transition: transform 3s ease-in-out;
  border-radius: 15px; 
}

@keyframes scroll {
  0%, 16.67% { transform: translateX(0%); }
  33.33% { transform: translateX(-100%); }
  50% { transform: translateX(-200%); }
  66.67% { transform: translateX(-100%); }
  83.33% { transform: translateX(0%); }
  100% { transform: translateX(100%); } 
}

.image-container .image {
  animation: scroll 18s linear infinite;
} */


/* About-us-text */


.about-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding-left: 5%;
  padding-right: 5%;
}

.about-us-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding-left: 5%;
  padding-right: 5%;
}

.about-us-text {

  width: 40%;
  height: 70%;
  /* height: auto; */
  position: relative;
  padding: 1.5rem;
  overflow-y: scroll !important;

  font-size: 1.2rem;
  text-align: justify;
  font-weight: lighter;

  color: gray;
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;

}

/* .about-us-text::-webkit-scrollbar {
  display: none;
} */
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.removescroll::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}


.about-us-text h2 {
  text-align: center;
  /* margin-top: 5px; */
  color: black;
  margin-bottom: 10px;
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;

}

.about-us-image {
  transition: transform 0.3s ease-in-out;
  width: 60%;


}

.about-us-image img {

  width: 100%;
  object-fit: cover;
  /* right: 5%; */
  border-radius: 20px;
}

.about-us-image:hover {
  transform: scale(1.03);
}




.vision-mission-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  gap: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(0deg, rgba(170, 182, 194, 1) 0%, rgba(24, 52, 91, 1) 87%, rgba(15, 41, 78, 1) 100%);


}

.vision-mission-text {
  width: 40%;
  position: relative;

  font-size: 1.2rem;
  text-align: justify;
  font-weight: lighter;

  color: gray;
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;
}

.vision-mission-text h2 {
  text-align: center;

  color: white;
  margin-bottom: 10px;
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;

}

.change {
  position: relative;
  left: 26%;
  /* right: 20%; */
  width: 50%;
  margin-bottom: 2rem;
}

.fix-3 {
  /* font-size: 1.2rem; */
  text-align: justify;
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;
  color: white;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}

.vision-mission-text {
  position: relative;
  width: 40%;
  /* right: 5%; */
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;

}



.vision-mission-image:hover {
  transform: scale(1.1);
}

.privatejet-container {
  display: flex;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  /* align-items: center; */
  padding: 4rem;
  background-color: rgba(255, 255, 255, 0.8);
  /* z-index: 2; */
  /* margin-top: 2rem; */
  background: white;

}

.privatejet-text h2 {
  text-align: center;
  margin-top: 5px;
  color: black;
  margin-bottom: 10px;
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;
  /* font-size: 3rem; */
}

.fix-start {
  position: relative;
  left: -32%;
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    18px;


}

.new {
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;
}

.privatejet-text {

  /* flex: 1; */
  width: 45%;
  text-align: justify;
  /* font-size: 1.5rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: gray;
  /* padding: 10rem; */
  /* color: gray; */
  /* width: 50%; */
  align-items: center;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  /* font-size: 1.2rem; */
  text-align: justify;
  font-weight: lighter;
  /* margin-right: 150px; */
  /* line-height: normal; */
}


.privatejet-image {
  flex: 1;
  /* width: 100%; */

  height: 550px;
  /* padding-left: 50px; */
  margin-left: 45px;
  /* padding: 10rem; */
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
}

.privatejet-image:hover {
  transform: scale(1.1);
}

.private-aviation-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* align-items: center; */
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #2b2b2b;
  background: rgb(170, 182, 194);
  background: linear-gradient(0deg, rgba(170, 182, 194, 1) 0%, rgba(24, 52, 91, 1) 87%, rgba(15, 41, 78, 1) 100%);
  /* padding: 4rem; */
  /* z-index: 2; */
  /* margin-top: 2rem; */
  /* height: 60vh; */
}


.private-aviation-text {
  /* flex: 1; */
  display: flex;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;


  /* gap: 10px; */
  color: white;
  /* font-size: 1.2rem; */
  /* left: -5%; */
  /* padding: 10rem; */
  color: white;
  width: 40%;
  /* align-items: center; */
  text-align: justify;
  /* margin-right: px; */
  /* line-height: normal;
   */
}

.private-aviation-text h2 {
  font-weight:
    700;
  line-height:
    41.25px;
  font-size:
    30px;
}

.private-aviation-text li {
  font-weight:
    400;
  line-height:
    26px;
  font-size:
    16px;
}

.newlgz {
  /* left: -2%; */
  transform: translateX(-2%);
  position: relative;
  margin-top: -3rem;

}

.private-image-con {
  /* flex: 1; */
  width: 60%;
  /* height: 550px; */
  /* padding-left: 50px; */
  /* margin-right: 185px; */
  /* padding: 10rem; */
  border-radius: 20px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.private-image-con img {
  width: 100%;
}

.private-image-con:hover {
  transform: scale(1.1);

}

/* Footer Styles */
.footer {
  /* border-top: solid 2px gray; */
  background-color: white;
  /* color: #fff; */
  padding: 2rem;
  height: 120vh;
  width: 100vw;
  text-align: center;
}

.fix-4 {
  color: black;
  font-size: 1.5rem;
  /* text-align: justify; */
  margin-top: 2rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.fix-5 {
  font-size: 1.7rem;
  text-decoration: underline;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  /* text-align: justify; */
}

.footer a {
  color: rgba(116, 72, 183, 0.637);
  text-decoration: underline;
}

.query-form {
  margin-top: 2rem;
}

.query-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.query-form input,
.query-form textarea {
  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.query-form button {
  padding: 0.5rem 2rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  height: 60px;
  width: 180px;
  text-align: center;
  font-size: 20px;
}

.query-form button:hover {
  background-color: #0056b3;
}



.countryStrap {
  height: 40vh;
  text-align: center;
  background-color: #aab5c1;
  color: rgb(245, 245, 245);
  padding-top: 6%;
}

/*
.countryStrap p{
  font-size: 25px;
  text-transform: capitalize;
} */

/* fix code  */


/* Responsive Styles */


/* @media (max-width: 480px) {
  .overlay h1 {
    font-size: 1.5rem;
  }

  .overlay p {
    font-size: 0.875rem;
  }

  .content h2 {
    font-size: 1.25rem;
  }

  .content p {
    font-size: 0.875rem;
  }
} */




/* App.css */

.countryStrap::before,
.countryStrap::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: linear-gradient(45deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 75%);
  background-size: 150% 150%;
  animation: grainAnimation 10s linear infinite;
}

@keyframes grainAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 100%;
  }
}

.countryStrap p {
  font-size: 1.5rem;
  /* Adjust as needed */
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  white-space: nowrap;
}

.countryStrap p {
  font-size: 1.5rem;
  /* Adjust as needed */
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 1.5s ease-out;
  white-space: nowrap;
}



.about-us-text {
  width: 100%;
  /* Full width on mobile */
  padding: 1rem;
  /* Adjust padding for mobile */
  font-size: 1rem;
  /* Adjust font size for mobile */
  text-align: left;
  /* Adjust text alignment for mobile */
  overflow-y: visible;
  /* Allow content to expand */
}

.about-us-image {
  width: 100%;
  margin-top: 1rem;
  /* Add space between text and image */
}


.last {
  color: gray;
  text-align: center;
  font-size: 16px;
  margin-top: 9%;
  position: relative;
  top: -10%;
  position: sticky;
  background-color: lightgray;
}

/* .about-us-text p{
    font-style: 16px !important;
  } */

.viss-miss-h2 {
  margin-top: 0.1rem;
  font-size: 18px;
  text-align: left;
  margin-bottom: -2vh;
}

.about-us-container2 {
  background:
    linear-gradient(0deg, rgba(170, 182, 194, 1) 0%, rgba(24, 52, 91, 1) 87%, rgba(15, 41, 78, 1) 100%);
}

.addon {
  overflow: hidden !important;
  color: white;
}

.whyy-h2 {
  color: white !important;
  margin-top: 12vh !important;
}

.hide-web {
  display: none;
  visibility: hidden;

}


@media (max-width: 768px) {
  .overlay {
    box-sizing: border-box;
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
    padding: 0 1rem;
    height: 65%;
    width: 70%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .overlay h1 {
    font-size: 20px;
  }

  .hide-web {
    display: flex;
    visibility: visible;
    justify-content: center;
    text-align: center;
    /* margin-top: 5px; */
    color: black;
    margin-bottom: 10px;
    font-weight:
      700;
    line-height:
      41.25px;
    font-size:
      30px;

  }

  .overlay p {
    font-size: 12px;
    text-align: center;

  }

  .image-container {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }

  .imageOne {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
  }

  .image {
    width: 100%;
    /* margin-bottom: 1rem; */
  }

  .newlgz {
    height: 100px;
    margin-top: -1rem;
  }


  .vision-mission-container,
  .privatejet-container,
  .private-aviation-container {
    flex-direction: column;
    margin-top: 100vh;
  }

  .about-us-text,
  .vision-mission-text,
  .privatejet-text,
  .private-aviation-text {
    padding: 2rem;
    width: 100%;
    text-align: justify;
  }

  .about-us-image,
  .vision-mission-image,
  .privatejet-image,
  .private-aviation-image {
    width: 100%;
    padding: 1rem 1rem;
  }

  .media-fix {
    overflow-y: visible;
  }

  .about-us-container {
    flex-direction: column;
    /* margin-top: 242%; */
    /* height: 111vh; */
    height: auto;
  }

  .about-us-container2 {
    flex-direction: column;
    margin-top: 10%;
    /* height: 111vh; */
    height: auto;
  }

  .last {
    margin-top: 0;
  }

  .about-us-text h2 {
    text-align: center;
    /* margin-top: 5px; */
    color: black;
    margin-bottom: 0px;
    font-weight: 700;
    line-height: 1.2rem;
    font-size: 16px;
  }

  .respo-p {
    font-size: 14px !important;
    text-align: justify;
  }

  .hide-mobile {
    visibility: hidden;
    display: none;
  }



}

@media (min-width: 800px) and (max-width: 1300px) {
  .overlay h1 {
    font-size: 2rem;
    /* margin-bottom: 1rem; */
  }

  .newlgz {
    transform: translateX(-2%);
    margin-top: -49px;
    position: relative;
  }
}