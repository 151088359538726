@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.wrapperz {
  /* height: 100vh;
  width: 100%; */
  position: relative;
  /* perspective: 10px; */
  overflow-x: hidden;
}

.top {
  display: flex;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  z-index: -1;
  height: 100vh;
}

#img1 {
  height: 100vh;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}

.main-text {
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 3px 5px 2px #474747;
}

.section {
  background-color: rgb(255, 255, 255);
  color: aliceblue;
  padding: 2rem 0;
}

.secheading {
  font-size: 5rem;
  padding: 0 10rem;
}

.bg {
  position: relative;
  background-attachment: fixed;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg1 {

  background-image: url('../Assets/Challenger 605 Exterior Above Desert.jpg');
}

.bg2 {
  background-image: url('../Assets/Challenger 605 Exterior Over Clouds.jpg');
}

.bg3 {
  background-image: url('../Assets/Challenger 605 Exterior Side view (3).jpg');
}

.bg4 {
  background-image: url('../Assets/Challenger 605 Interior Rear.jpg');
}

.bg5 {
  background-image: url('../Assets/Challenger 605 Interior Cabin.jpg');
}

.bg6 {
  background-image: url('../Assets/Challenger 605 Interior.jpg');
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #9e9e9e;
  margin: 5rem 0;
  padding: 0 10rem;
}

.newlg {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin-top: -8rem;
}

.desc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  font-size: 4rem;
}

@media (max-width: 768px) {
  .bg {
    height: 375px
  }

  .bg1 {
    margin-top: 70px;
  }

  .slide-left {
    animation: slideInLeft 1s ease-in-out;
  }

  .slide-right {
    animation: slideInRight 1s ease-in-out;
  }

  #img1 {
    height: auto;
    width: 100%;
  }

  .main-text {
    font-size: 2rem;
  }

  .secheading {
    font-size: 3rem;
    padding: 0 2rem;
  }

  .text {
    padding: 0 2rem;
    font-size: 14px;
    text-align: justify;
  }

  .newlg {
    height: 100px;
    margin-top: -10rem;
    position: relative;
    left: 50%;
    /* transform: translateX(-50%) translateY(60%); */
  }

  .bg {
    background-attachment: scroll;
    background-size: contain;
  }

  [data-aos] {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
}

@media (min-width: 769px) {}